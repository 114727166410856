exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-coleccion-slug-tsx": () => import("./../../../src/pages/coleccion/[...slug].tsx" /* webpackChunkName: "component---src-pages-coleccion-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-lp-bombazos-tsx": () => import("./../../../src/pages/lp/bombazos.tsx" /* webpackChunkName: "component---src-pages-lp-bombazos-tsx" */),
  "component---src-pages-lp-cambios-devoluciones-tsx": () => import("./../../../src/pages/lp/cambios-devoluciones.tsx" /* webpackChunkName: "component---src-pages-lp-cambios-devoluciones-tsx" */),
  "component---src-pages-lp-descuentos-tsx": () => import("./../../../src/pages/lp/descuentos.tsx" /* webpackChunkName: "component---src-pages-lp-descuentos-tsx" */),
  "component---src-pages-lp-garantia-200-tsx": () => import("./../../../src/pages/lp/garantia200.tsx" /* webpackChunkName: "component---src-pages-lp-garantia-200-tsx" */),
  "component---src-pages-lp-imbatibles-tsx": () => import("./../../../src/pages/lp/imbatibles.tsx" /* webpackChunkName: "component---src-pages-lp-imbatibles-tsx" */),
  "component---src-pages-lp-ofertas-tsx": () => import("./../../../src/pages/lp/ofertas.tsx" /* webpackChunkName: "component---src-pages-lp-ofertas-tsx" */),
  "component---src-pages-lp-politica-de-privacidad-tsx": () => import("./../../../src/pages/lp/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-lp-politica-de-privacidad-tsx" */),
  "component---src-pages-lp-preguntas-frecuentes-tsx": () => import("./../../../src/pages/lp/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-lp-preguntas-frecuentes-tsx" */),
  "component---src-pages-lp-revista-tsx": () => import("./../../../src/pages/lp/revista.tsx" /* webpackChunkName: "component---src-pages-lp-revista-tsx" */),
  "component---src-pages-lp-terminos-y-condiciones-tsx": () => import("./../../../src/pages/lp/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-lp-terminos-y-condiciones-tsx" */),
  "component---src-pages-lp-tsx": () => import("./../../../src/pages/lp.tsx" /* webpackChunkName: "component---src-pages-lp-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */)
}

