import { Button } from '@faststore/ui'
import { ButtonIcon } from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import { Image } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'
import { useUI } from 'src/sdk/ui/Provider'

type TNavlinks = {
  collectionListData: CollectionsListData
}

const DisplayCollectionList = ({
  collections,
}: {
  collections: CollectionsList[]
}) => {
  return (
    <>
      {collections?.map(({ link, text }, idx) => (
        <Link
          className="navlinks__collection--text"
          variant="display"
          href={link}
          aria-label="Collection Text"
          key={idx}
        >
          {text}
        </Link>
      ))}
    </>
  )
}

function NavLinks({ collectionListData }: TNavlinks) {
  const { openNavbar } = useUI()

  return (
    <nav className="navlinks__list">
      <div className="navlinks__list-mobile display-mobile">
        <DisplayCollectionList collections={collectionListData?.allItems} />
        <div className="navlinks__contact" style={{ display: 'flex' }}>
          <Image
            src="https://tatauyqa.vtexassets.com/arquivos/tel.gif"
            alt="phone"
            width={23}
            height={23}
            loading="lazy"
          />
          <div
            dangerouslySetInnerHTML={{
              __html:
                collectionListData?.contactText ??
                '<p className="navlinks__contact-text"> Televentas <b>08008282</b></p>',
            }}
          />
        </div>
        <div
          className="navlinks__container__redlink"
          style={{
            backgroundColor:
              collectionListData?.buttonMenu?.buttonBackgroundColor ??
              '#e5002b',
          }}
        >
          <Link
            className="navlinks__redlink__box"
            href={
              collectionListData?.buttonMenu?.buttonLink ??
              '/lp/bombazos/?utm_source=web&utm_medium=boton&utm_campaign=bombazosfrescos&utm_id=web'
            }
            aria-label="Collection Link"
            style={{
              color: collectionListData?.buttonMenu?.buttonColor ?? '#fff',
            }}
          >
            {collectionListData?.buttonMenu?.buttonText ?? 'BOMBAZOS'}
          </Link>
        </div>
        <div className="navlinks__whats">
          <Link
            aria-label="Whatsapp"
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=%2B59898723988&text=Hola%2C+necesito+asistencia&type=phone_number&app_absent=0"
          >
            <img
              src="/WhatsAppLogo.svg"
              alt="bagIcon"
              width="60px"
              height="60px"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
      <div className="navlinks__category-box hidden-mobile">
        <div className="navlinks__categories" style={{ display: 'flex' }}>
          <ButtonIcon
            data-fs-button-menu
            testId="menu--desktop"
            aria-label="Open Menu"
            icon={<Icon name="ListMenu" width={19} height={18} />}
            onClick={openNavbar}
          />
          <Button
            className="navlinks__button--open"
            onClick={openNavbar}
            aria-label="Categorías"
          >
            <h2 className="navlinks__category-text">CATEGORÍAS</h2>
          </Button>
        </div>
        <DisplayCollectionList collections={collectionListData?.allItems} />
        <div className="navlinks__contact" style={{ display: 'flex' }}>
          <Image
            src="https://tatauyqa.vtexassets.com/arquivos/tel.gif"
            alt="phone"
            width={23}
            height={23}
            loading="lazy"
          />
          <div
            dangerouslySetInnerHTML={{
              __html:
                collectionListData?.contactText ??
                '<p className="navlinks__contact-text"> Televentas <b>08008282</b></p>',
            }}
          />
        </div>
        <div
          className="navlinks__container__redlink"
          style={{
            backgroundColor:
              collectionListData?.buttonMenu?.buttonBackgroundColor ??
              '#e5002b',
          }}
        >
          <Link
            className="navlinks__redlink__box"
            href={
              collectionListData?.buttonMenu?.buttonLink ??
              '/lp/bombazos/?utm_source=web&utm_medium=boton&utm_campaign=bombazosfrescos&utm_id=web'
            }
            style={{
              color: collectionListData?.buttonMenu?.buttonColor ?? '#fff',
            }}
            aria-label="Collection Link"
          >
            {collectionListData?.buttonMenu?.buttonText ?? 'BOMBAZOS'}
          </Link>
        </div>
        <div className="navlinks__whats">
          <Link
            aria-label="Whatsapp"
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=%2B59898723988&text=Hola%2C+necesito+asistencia&type=phone_number&app_absent=0"
          >
            <img
              src="/WhatsAppLogo.svg"
              alt="bagIcon"
              width="60px"
              height="60px"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default NavLinks
